export default [
  // { header: "Home" },
  {
    title: "الاساتذة",
    icon: "fa-user",
    to: "/dashboards/teachers",
  },
  {
    title: "الاشعارات",
    icon: "fa-bell",
    to: "/dashboards/notifications",
  },
  {
    title: "السواق",
    icon: "fa-car",
    group: "/dashboards/drivers",
    children: [
      {
        title: "عرض",
        icon: "fa-eye",
        to: "show",
      },
      {
        title: "الطلاب",
        icon: "fa-graduation-cap",
        to: "students",
      },
    ],
  },
  {
    title: "الدورات",
    icon: "fa-book",
    to: "/dashboards/courses",
  },
  {
    title: "الزبائن",
    icon: "fa-users",
    to: "/dashboards/customers",
  },
  {
    title: "انشاء دورة جديدة",
    icon: "fa-plus-square",
    to: "/dashboards/new_courses",
  },
  {
    title: "الاشتراكات",
    icon: "fa-file",
    to: "/dashboards/subscriptions",
  },
  {
    title: "ايرادات اخرى",
    icon: "fa-money-bill-alt",
    to: "/dashboards/subscriptions",
  },

  {
    title: "المصاريف",
    icon: "fa-money-bill-alt",
    group: "/dashboards/expenses",
    children: [
      {
        title: "عرض",
        icon: "fa-eye",
        to: "show",
      },
      {
        title: "النوع",
        icon: "fa-file",
        to: "type",
      },
    ],
  },
];

<template>
  <v-app id="inspire" :class="{ horizontalstyle: setHorizontalLayout }">
    <v-main :class="{ miniSide: expandOnHover }">
      <v-container class="page-wrapper">
        <!-- ---------------------------------- -->
        <!--- Vertical Header part -->
        <!-- ---------------------------------- -->
        <VerticalHeader
          v-if="!setHorizontalLayout"
          v-model="expandOnHover"
        ></VerticalHeader>
        <!-- ---------------------------------- -->
        <!--- Horizontal Header part -->
        <!-- ---------------------------------- -->
        <HorizontalHeader v-else></HorizontalHeader>
        <router-view />
        <!-- <v-btn
          bottom
          color="primary"
          dark
          fab
          fixed
          right
          @click.stop="setCustomizerDrawer(!Customizer_drawer)"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn> -->
      </v-container>
    </v-main>
    <!-- ---------------------------------- -->
    <!--- Vertical Sidebar  part -->
    <!-- ---------------------------------- -->
    <!-- <VerticalSidebar
      v-if="!setHorizontalLayout && type === 'admin'"
      :expand-on-hover.sync="expandOnHover"
    ></VerticalSidebar> -->
    <VerticalSidebar
      v-if="type === 'admin'"
      :expand-on-hover.sync="expandOnHover"
    ></VerticalSidebar>

    <VerticalSidebarSubAdmin
      v-else
      :expand-on-hover.sync="expandOnHover"
    ></VerticalSidebarSubAdmin>
    <!-- ---------------------------------- -->
    <!--- Horizontal Sidebar part -->
    <!-- ---------------------------------- -->
    <!-- <HorizontalSidebar v-else></HorizontalSidebar> -->
    <!-- ---------------------------------- -->
    <!--- Customizer part -->
    <!-- ---------------------------------- -->
    <Customizer v-model="expandOnHover"></Customizer>
    <!-- ---------------------------------- -->
    <!--- Vertical Footer part -->
    <!-- ---------------------------------- -->
    <!-- <Footer v-if="!setHorizontalLayout"></Footer> -->
    <!-- ---------------------------------- -->
    <!--- Horizontal Footer part -->
    <!-- ---------------------------------- -->
    <!-- <HorizontalFooter v-else></HorizontalFooter> -->
  </v-app>
</template>

<script>
import HorizontalHeader from './horizontal-header/HorizontalHeader'
import VerticalHeader from './vertical-header/VerticalHeader'
// import HorizontalSidebar from './horizontal-sidebar/HorizontalSidebar'
import VerticalSidebar from './vertical-sidebar/VerticalSidebar'
import VerticalSidebarSubAdmin from './vertical-sidebar_sub_admin/VerticalSidebar'

// import Footer from "./footer/Footer";
// import HorizontalFooter from "./horizontal-footer/HorizontalFooter";
import Customizer from './customizer/Customizer'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Layout',

  components: {
    HorizontalHeader,
    VerticalHeader,
    // HorizontalSidebar,
    VerticalSidebar,
    VerticalSidebarSubAdmin,
    Customizer
  },

  props: {
    source: String
  },

  data: () => ({
    expandOnHover: false,
    type: null,
    verticalSidebarAdmin: false
  }),
  created () {
    const results = JSON.parse(localStorage.getItem('results'))
    if (results.type) {
      this.type = results.type
    }
  },
  computed: {
    // type: function (val) {
    //   if (val === 'admin') {
    //     return 'admin'
    //   } else {
    //     return 'subAdmin'
    //   }
    // },
    ...mapState(['Customizer_drawer', 'setHorizontalLayout'])
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: 'SET_CUSTOMIZER_DRAWER'
    })
  }
}
</script>

<style></style>

<template>
  <v-app-bar
    app
    clipped-left
    clipped-right
    :color="navbarColor"
    :dark="navbarColor !== '#fafafa'"
    class="app-navbar"
  >
    <!-- ---------------------------------------------- -->
    <!---/Toggle sidebar part -->
    <!-- ---------------------------------------------- -->
    <div>
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />
    </div>
    <!---/Toggle sidebar part -->

    <!-- ---------------------------------------------- -->
    <!---Search part -->
    <!-- ---------------------------------------------- -->
    <!-- <v-btn icon class="mr-1 d-sm-block d-none" @click="searchbox"> -->
    <!-- <feather type="search" class="feather-sm"></feather> -->
    <!-- </v-btn> -->

    <v-card v-if="showSearch" class="searchinput">
      <template>
        <v-text-field
          placeholder="Search & hit enter"
          class="mt-3 mb-0"
          append-icon="mdi-close"
          @click:append="searchbox"
        ></v-text-field>
      </template>
    </v-card>
    <!---/Search part -->

    <v-spacer />
    <!-- ---------------------------------------------- -->
    <!---right part -->
    <!-- ---------------------------------------------- -->

    <!-- ---------------------------------------------- -->
    <!-- Messages -->
    <!-- ---------------------------------------------- -->
    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      min-width="385"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-badge color="secondary" dot offset-x="4" offset-y="4">
            <feather type="message-square" class="feather-sm"></feather>
          </v-badge>
        </v-btn>
      </template>

      <v-list class="pa-8">
        <h4 class="font-weight-medium fs-18">
          Messages
          <v-chip class="ma-2" label small color="secondary"> 5 new </v-chip>
        </h4>

        <v-list-item
          v-for="(item, i) in messages"
          class="px-0"
          :key="i"
          @click="href"
        >
          <v-list-item-title>
            <div class="d-flex align-center py-4 px-3 border-bottom">
              <div class>
                <v-badge
                  bordered
                  bottom
                  :color="item.avatarstatus"
                  dot
                  offset-x="20"
                  offset-y="40"
                >
                  <v-avatar size="42" class="mr-3"
                    ><img
                      :src="require(`@/assets/images/users/${item.image}`)"
                      :alt="item.image"
                  /></v-avatar>
                </v-badge>
              </div>
              <div class="ml-3">
                <h4 class="font-weight-medium">{{ item.title }}</h4>
                <span
                  class="grey--text subtitle-2 descpart d-block text-truncate font-weight-regular"
                  >{{ item.desc }}</span
                >
                <small class="grey--text caption">{{ item.time }}</small>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-btn block depressed color="primary" class="mt-4 py-4"
          >See all Messages</v-btn
        >
      </v-list>
    </v-menu> -->

    <!-- ---------------------------------------------- -->
    <!-- Notification -->
    <!-- ---------------------------------------------- -->
    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      min-width="385"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-badge color="primary" dot offset-x="5" offset-y="5">
            <feather type="bell" class="feather-sm"></feather>
          </v-badge>
        </v-btn>
      </template>

      <v-list class="pa-8">
        <h4 class="font-weight-medium fs-18">
          Notifications
          <v-chip class="ma-2" label small color="warning"> 5 new </v-chip>
        </h4>
        <v-list-item
          class="px-0"
          v-for="(item, i) in notifications"
          :key="i"
          @click="href"
        >
          <v-list-item-title>
            <div class="d-flex align-center py-4 px-3 border-bottom">
              <div class>
                <v-btn :color="item.color" fab small elevation="0" class="mr-3">
                  <feather :type="item.icon" size="18"></feather>
                </v-btn>
              </div>
              <div class="ml-2">
                <h4 class="font-weight-medium">{{ item.title }}</h4>
                <span
                  class="grey--text subtitle-2 descpart d-block text-truncate font-weight-regular"
                  >{{ item.desc }}</span
                >
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-btn block depressed color="secondary" class="mt-4 py-4"
          >See all Notifications</v-btn
        >
      </v-list>
    </v-menu> -->

    <!-- ---------------------------------------------- -->
    <!-- User Profile -->
    <!-- ---------------------------------------------- -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      min-width="385"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="pa-0 px-1"
          elevation="0"
          color="transparent"
          plain
          :ripple="false"
        >
          <v-avatar size="35">
            <img src="@/assets/images/users/user2.jpg" alt="Julia" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list class="pa-8">
        <h4 class="font-weight-medium fs-18">ملف المستخدم</h4>
        <div class="d-flex align-center my-4">
          <img
            src="@/assets/images/users/user2.jpg"
            alt="Julia"
            class="rounded-circle"
            width="90"
          />
          <div class="ml-4">
            <h4 class="font-weight-medium fs-18">{{ data.name }}</h4>
            <span class="subtitle-2 font-weight-light">{{ data.type }}</span>
            <div class="d-flex align-center">
              <feather
                type="mail"
                size="16"
                class="d-flex grey--text"
              ></feather>
              <span class="subtitle-2 font-weight-light ml-1">{{
                data.email
              }}</span>
            </div>
          </div>
        </div>
        <!-- <v-list-item class="px-0" @click="goToSetting">
          <v-list-item-title>
            <div class="d-flex align-center py-4 px-3 border-bottom">
              <div class>
                <v-btn fab elevation="0" class="mr-3 bg-light-success">
                  <span class="success--text">
                    <feather type="settings" width="20"></feather>
                  </span>
                </v-btn>
              </div>
              <div class="ml-2">
                <h4 class="font-weight-medium">الاعدادات</h4>
                <span
                  class="
                    grey--text
                    subtitle-2
                    descpart
                    d-block
                    text-truncate
                    font-weight-regular
                  "
                  >اعدادات المستخدم</span
                >
              </div>
            </div>
          </v-list-item-title>
        </v-list-item> -->
        <v-btn
          block
          depressed
          color="secondary"
          class="mt-4 py-4"
          :loading="logoutLoading"
          @click="logout"
          >تسجيل خروج</v-btn
        >
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import api from "@/api/api";
export default {
  name: "VerticalHeader",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    logoutLoading: false,
    showSearch: false,
    drawer: false,
    group: null,

    data: {
      name: null,
      email: null,
      type: null,
    },

    messages: [
      {
        image: "1.jpg",
        avatarstatus: "success",
        title: "Romen Joined the Team!",
        desc: "Congratulate him",
        time: "9:30 AM",
      },
      {
        image: "2.jpg",
        avatarstatus: "warning",
        title: "New message received",
        desc: "Salma sent you new message",
        time: "9:08 AM",
      },
      {
        image: "3.jpg",
        avatarstatus: "error",
        title: "New Payment received",
        desc: "Check your earnings",
        time: "9:08 AM",
      },
      // {
      //   image: "4.jpg",
      //   avatarstatus: "success",
      //   title: "Jolly completed tasks",
      //   desc: "Assign her new tasks",
      //   time: "9:08 AM",
      // },
    ],
    notifications: [
      {
        color: "error",
        icon: "home",
        title: "Luanch Admin",
        desc: "Just see the my new admin!",
      },
      {
        color: "primary",
        icon: "calendar",
        title: "Event today",
        desc: "Just a reminder that you have event",
      },
      {
        color: "success",
        icon: "settings",
        title: "Settings",
        desc: "You can customize this template as you want",
      },
      {
        color: "secondary",
        icon: "users",
        title: "Johny John",
        desc: "Assign her new tasks",
      },
    ],
    userprofile: [
      {
        color: "error",
        icon: "dollar-sign",
        title: "My Profile",
        desc: "Account Settings",
      },
      {
        color: "success",
        icon: "shield",
        title: "My Inbox",
        desc: "Messages & Emails",
      },
      {
        color: "secondary",
        icon: "credit-card",
        title: "My Tasks",
        desc: "To-do and Daily Tasks",
      },
    ],
    href() {
      return undefined;
    },
  }),

  computed: {
    ...mapState(["navbarColor", "Sidebar_drawer"]),
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),

    async logout() {
      this.logoutLoading = true;
      await api.logout();
      this.$store.dispatch("submitLogout");
      this.logoutLoading = false;
    },

    searchbox: function () {
      this.showSearch = !this.showSearch;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },

  },
};
</script>

<style lang="scss">
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}

@media (min-width: 960px) {
  .v-application--is-ltr {
    .app-navbar.v-sheet {
      width: calc(100% - 265px) !important;
      margin-left: auto;
    }
  }
  .v-application--is-rtl {
    .app-navbar.v-sheet {
      width: calc(100% - 265px) !important;
      margin-left: unset;
      margin-right: auto;
    }
  }
}
</style>
